<div>
  <div id="header-bar">
    <a class="logo" routerLink="/">
      <img
        src="a/icons/icon-32x32.png"
        style="height: 24px; margin-right: 12px"
        alt="DevFestMN 2024"
        />
      <span>DevFest<strong>MN</strong></span></a
      >
      <span style="flex-grow: 1"></span>
      <div id="header-menu">
        @if (environment.showRegister) {
          <a
            id="global-ticket-button"
            (mouseenter)="loadEBWidget()"
            (focus)="loadEBWidget()"
            (click)="lazyClickEBWidget()"
            class="button"
            >Tickets</a
            >
          }
          @if (environment.showSchedule) {
            <a [routerLink]="['/schedule']">Schedule</a>
          }
          @if (!environment.showSpeakers) {
            <a [routerLink]="['speaker-cfp']">Be a Speaker</a>
          }
          @if (environment.showSpeakers) {
            <a [routerLink]="['/speakers']">Speakers</a>
          }
          <!-- <a routerLink="/sponsors">Sponsor</a> -->
        </div>
      </div>
      <div [@routeAnimation]="prepRouteState(routerOutlet)" class="routeContainer">
        <router-outlet #routerOutlet="outlet"></router-outlet>
      </div>
      <footer>
        <div class="blocks">
          <div>
            <h5>Links</h5>
            <a href="http://gdgtc.com" target="_blank" href="http://gdgtc.com"
              >GDG Twin Cities</a
              >
              <a href="https://developers.google.com/" target="_blank">Google Developers</a>
              <a href="https://github.com/stephenfluin/devfest-mn" target="_blank">GitHub</a>
              <a routerLink="/admin/">Admin</a>
              <a href="https://past.devfest.mn/past/">Previous Years</a>
            </div>
            <div>
              <h5>Contact</h5>
              <a href="mailto:info@gdgtc.com" target="_blank">info&#64;gdgtc.com</a>
              <a href="http://twitter.com/home?status=@gdgtc" target="_blank">&#64;gdgtc</a>
            </div>
            <div>
              <h5>FAQ</h5>
              <a href="https://developers.google.com/groups/" target="_blank"
                >Google Developer Groups</a
                >
                <a href="https://developers.google.com/events/devfest/" target="_blank"
                  >GDG DevFest Season</a
                  >
                  <a href="https://developers.google.com/groups/guidelines/" target="_blank"
                    >GDG Code of Conduct</a
                    >
                  </div>
                </div>
                <section class="social-links">
                  <a href="https://twitter.com/gdgtc" target="_blank">
                    <svg style="width: 24px; height: 24px" viewBox="0 0 24 24">
                      <path
                        fill="#FFFFFF"
                        d="M17.71,9.33C17.64,13.95 14.69,17.11 10.28,17.31C8.46,17.39 7.15,16.81 6,16.08C7.34,16.29 9,15.76 9.9,15C8.58,14.86 7.81,14.19 7.44,13.12C7.82,13.18 8.22,13.16 8.58,13.09C7.39,12.69 6.54,11.95 6.5,10.41C6.83,10.57 7.18,10.71 7.64,10.74C6.75,10.23 6.1,8.38 6.85,7.16C8.17,8.61 9.76,9.79 12.37,9.95C11.71,7.15 15.42,5.63 16.97,7.5C17.63,7.38 18.16,7.14 18.68,6.86C18.47,7.5 18.06,7.97 17.56,8.33C18.1,8.26 18.59,8.13 19,7.92C18.75,8.45 18.19,8.93 17.71,9.33M20,2H4A2,2 0 0,0 2,4V20A2,2 0 0,0 4,22H20A2,2 0 0,0 22,20V4C22,2.89 21.1,2 20,2Z"
                      ></path>
                    </svg>
                  </a>
                  <a href="https://github.com/GDGTC" target="_blank">
                    <svg style="width: 24px; height: 24px" viewBox="0 0 24 24">
                      <path
                        fill="#FFFFFF"
                        d="M4,2H20A2,2 0 0,1 22,4V20A2,2 0 0,1 20,22H14.85C14.5,21.92 14.5,21.24 14.5,21V18.26C14.5,17.33 14.17,16.72 13.81,16.41C16.04,16.16 18.38,15.32 18.38,11.5C18.38,10.39 18,9.5 17.35,8.79C17.45,8.54 17.8,7.5 17.25,6.15C17.25,6.15 16.41,5.88 14.5,7.17C13.71,6.95 12.85,6.84 12,6.84C11.15,6.84 10.29,6.95 9.5,7.17C7.59,5.88 6.75,6.15 6.75,6.15C6.2,7.5 6.55,8.54 6.65,8.79C6,9.5 5.62,10.39 5.62,11.5C5.62,15.31 7.95,16.17 10.17,16.42C9.89,16.67 9.63,17.11 9.54,17.76C8.97,18 7.5,18.45 6.63,16.93C6.63,16.93 6.1,15.97 5.1,15.9C5.1,15.9 4.12,15.88 5,16.5C5,16.5 5.68,16.81 6.14,17.97C6.14,17.97 6.73,19.91 9.5,19.31V21C9.5,21.24 9.5,21.92 9.14,22H4A2,2 0 0,1 2,20V4A2,2 0 0,1 4,2Z"
                      ></path>
                    </svg>
                  </a>
                </section>
                <section>
                  &copy; 2024 GDG Twin Cities<br />
                  Built by <a href="//twitter.com/littlelloyd">Lloyd Lentz</a> and
                  <a href="https://twitter.com/stephenfluin">Stephen Fluin</a>.
                </section>
              </footer>
            </div>
